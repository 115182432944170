.hapimap--container {
  position: relative;
  height: 100%;
  min-height: inherit;
  margin: 0;
  padding: 0;
}

.hapimap--container.loaded {
  position: static;
}
.hapimap--container.loaded .hapimap--loader {
  display: none;
}

.hapimap--loader {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #cde5fe;
  background: linear-gradient(to right top, #b8cce0, #dbe6f0);
}
.hapimap--container.js-loaded .hapimap--loader {
  background: linear-gradient(to right top, #9bccfd, #cde6fe);
}
.hapimap--loader .protected .box {
  width: 90px;
  height: 90px;
  margin: auto;
  border-radius: 100%;
  border: 4px solid white;
  background: white;
  box-shadow: 0 2px 150px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to left top, #9de3fb, #19bef5);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hapimap--loader .protected .box img {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.8;
  mix-blend-mode: overlay;
  z-index: 10;
  border-radius: 50%;
}
.hapimap--loader .protected .wave {
  opacity: 0.4;
  position: absolute;
  top: 0%;
  left: 50%;
  background: rgba(15, 16, 35, 0.56);
  width: 200px;
  height: 200px;
  margin-left: -100px;
  margin-top: -155px;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 2.5s infinite linear;
}
.hapimap--loader .protected .wave.-three {
  animation: drift 5s infinite linear;
}
.hapimap--loader .protected .wave.-two {
  animation: drift 9s infinite linear;
  opacity: 0.8;
  background: #0f1023;
}
.hapimap--loader .protected .box:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  transform: translate3d(0, 0, 0);
}
.hapimap--loader .protected .title,
.hapimap--loader .protected .credit {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, 0, 0);
  color: white;
  text-transform: uppercase;
  font-family: sans-serif;
  letter-spacing: 2px;
  font-size: 9px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  text-indent: 0.3em;
}
.hapimap--loader .protected .title {
  bottom: 30%;
  animation: pulsate 2s infinite linear;
  display: none;
}
.hapimap--container .hapimap--loader .protected .title.load {
  display: flex;
}
.hapimap--container.js-loaded .hapimap--loader .protected .title.load {
  display: none;
}
.hapimap--container.js-loaded .hapimap--loader .protected .title.init {
  display: flex;
}
.hapimap--loader .protected .credit {
  top: 50%;
  color: #0f1023;
}
@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  0% {
    opacity: 1;
  }
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=HapimapLoader.css.map */
